import React, { useMemo } from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import { getClientProps } from 'src/components/staff-users/client-props';
import StaffUsers from 'src/components/staff-users/staff-users';

const StaffUsersPage = () => {
  const clientProps = useMemo(() => getClientProps()?.staffUsersPage ?? {}, []);
  return (
    <Layout>
      <SEO title="Staff Users" />
      <PrivateRoute>
        <StaffUsers {...clientProps} />
      </PrivateRoute>
    </Layout>
  );
};

export default StaffUsersPage;
