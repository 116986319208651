import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ButtonModal from 'src/components/modal/modal';
import { BodyRegular } from 'src/components/typography/typography';
import { VenueContact } from 'src/types/classic';
import { addVenueContactSystemRole } from 'src/utils/classic-api';
import { getFormattedFullName } from 'src/utils/text';

import { BodyRegularBold } from '@clubspark-react/clubspark-react-tools';

import * as styles from './remove-staff-user-modal.module.less';

type Response = Awaited<ReturnType<typeof addVenueContactSystemRole>>;
interface RemoveStaffModalProps {
  staffUser: VenueContact;
  isRemoving: boolean;
  setIsRemoving: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onRemoveSuccess?: (response: Response) => void;
  onRemoveFail?: (error: Error) => void;
}

const RemoveStaffUserModal = ({
  staffUser,
  isRemoving,
  setIsRemoving,
  isOpen,
  setIsOpen,
  onRemoveSuccess,
  onRemoveFail,
}: RemoveStaffModalProps) => {
  const { t } = useTranslation();

  const handleRemoveStaffUser = useCallback(async () => {
    let response: Response | undefined;
    let error: Error | undefined;
    const requestPayload = {
      ID: staffUser.ID,
      Role: 0,
      Scope: 0,
      ReplaceCurrentRoles: false,
    };
    setIsRemoving(true);

    try {
      response = await addVenueContactSystemRole(requestPayload);
    } catch (err) {
      error = err as Error;
    }

    setIsRemoving(false);
    setIsOpen(false);
    response && onRemoveSuccess?.(response);
    error && onRemoveFail?.(error);
  }, [staffUser, onRemoveSuccess, onRemoveFail, setIsOpen, setIsRemoving]);

  return (
    <ButtonModal
      actionButtons={[
        {
          id: 'delete',
          content: t('delete'),
          props: {
            type: 'submit',
            level: 'warning',
            onClick: handleRemoveStaffUser,
            loading: isRemoving,
          },
        },
      ]}
      show={isOpen}
      cancelButton
      title={t('delete staff')}
      openButton={{ hidden: true }}
      onVisibilityChange={setIsOpen}
      hideCloseButton
      className={styles.modalContent}
      zIndex={10}
    >
      <BodyRegular spacing={{ margins: { sm: 'bottom' } }}>
        {t('delete staff warning', {
          staff:
            staffUser.FirstName && staffUser.LastName
              ? getFormattedFullName([staffUser.FirstName, staffUser.LastName])
              : t('user'),
        })}
      </BodyRegular>
      <BodyRegularBold>{t('delete staff question')}</BodyRegularBold>
    </ButtonModal>
  );
};

export const useRemoveStaffUserModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  return {
    isOpen,
    setIsOpen,
    isRemoving,
    setIsRemoving,
    RemoveStaffUserModal: useCallback(
      ({
        staffUser,
        onRemoveSuccess,
        onRemoveFail,
      }: Omit<RemoveStaffModalProps, 'isOpen' | 'setIsOpen' | 'isRemoving' | 'setIsRemoving'>) => (
        <RemoveStaffUserModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isRemoving={isRemoving}
          setIsRemoving={setIsRemoving}
          staffUser={staffUser}
          onRemoveSuccess={onRemoveSuccess}
          onRemoveFail={onRemoveFail}
        />
      ),
      [isOpen, isRemoving],
    ),
  };
};

export default RemoveStaffUserModal;
