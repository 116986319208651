import { ColumnDefinition } from 'src/components/admin-table/hooks';

export const defaultColumnsConfig: ColumnDefinition[] = [
  {
    key: 'FirstName',
    header: 'first name',
  },
  {
    key: 'LastName',
    header: 'last name',
  },
  {
    key: 'EmailAddress',
    header: 'email',
  },
  {
    key: 'AccessLevel',
    header: 'role',
    transformer: {
      type: 'staffUserRole',
      args: {
        accessor: 'Scope',
      },
    },
    sort: false,
  },
  {
    key: 'Scope',
    header: 'scope',
    transformer: { type: 'staffUserScopeList' },
    sort: false,
  },
  {
    key: 'Status',
    header: 'status',
    transformer: {
      type: 'staffRegistrationStatus',
      args: {
        accessor: 'InvitationExpiryDateTime',
        accessorCreatedDateTime: 'CreatedDateTime',
        type: 'staff',
      },
    },
    sort: false,
  },
];
