import React, { ReactNode, useCallback } from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button, { ButtonProps } from 'src/components/button/button';
import Icon, { IconProps } from 'src/components/icon/icon';

import * as styles from './expanded-menu.module.less';

export interface ExpandedMenuItem {
  key: string;
  label: ReactNode;
  onClick?: () => void;
  hide?: boolean;
  warning?: boolean;
  preventCloseOnClick?: boolean;
  component?: React.ComponentType;
}

export interface ExpandedMenuProps {
  items: ExpandedMenuItem[];
  onSelect?: any;
  buttonText: string;
  buttonProps?: ButtonProps;
  buttonIcon?: boolean;
  iconName?: IconProps['name'];
  anchorElement: 'button' | 'icon' | ((event: any) => JSX.Element);
  menuProps?: Partial<MenuProps>;
}

const ExpandedMenu: React.FC<ExpandedMenuProps> = ({
  items,
  onSelect,
  buttonText,
  buttonIcon,
  iconName,
  anchorElement,
  buttonProps = {},
  menuProps = {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleItemClick = useCallback(
    (_: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: ExpandedMenuItem) => {
      if (item.onClick) item.onClick();
      if (!item.preventCloseOnClick) setAnchorEl(null);
    },
    [setAnchorEl],
  );

  const getAnchorElement = useCallback(() => {
    switch (anchorElement) {
      case 'button':
        return (
          <Button level="tertiary" onClick={handleClick} {...buttonProps}>
            {buttonText}
            {buttonIcon && <Icon name={iconName ?? 'sm-down'} light={true} />}
          </Button>
        );
      case 'icon':
        return (
          <Icon
            role="button"
            name={iconName ?? 'sm-down'}
            light={true}
            onClick={handleClick}
            className={styles.iconBtn}
          />
        );
      default:
        return anchorElement(handleClick);
    }
  }, [anchorElement, iconName, handleClick, buttonIcon, buttonText, buttonProps]);

  return (
    <div>
      {getAnchorElement()}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onChange={onSelect}
        classes={{ paper: styles.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...menuProps}
      >
        {items.map((item) => {
          return (
            item &&
            !item.hide && (
              <MenuItem
                key={item.key}
                className={item.warning ? styles.menuItemWarning : styles.menuItem}
                onClick={(e) => handleItemClick(e, item)}
                component={item.component ?? 'span'}
              >
                {item.label}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </div>
  );
};

export default ExpandedMenu;
