// extracted by mini-css-extract-plugin
export var bodyBase = "remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var bodyLarge = "remove-staff-user-modal-module--body-large--c5fff remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var bodyLargeBold = "remove-staff-user-modal-module--body-large-bold--96788 remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var bodyRegular = "remove-staff-user-modal-module--body-regular--db8cd remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var bodyRegularBold = "remove-staff-user-modal-module--body-regular-bold--e1028 remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var bodySmall = "remove-staff-user-modal-module--body-small--dd827 remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var bodySmallBold = "remove-staff-user-modal-module--body-small-bold--22175 remove-staff-user-modal-module--body-base--60724 remove-staff-user-modal-module--site-font--f35b6";
export var borderTop = "remove-staff-user-modal-module--border-top--ee599";
export var breakWordContainer = "remove-staff-user-modal-module--break-word-container--f3b38";
export var buttonIconBase = "remove-staff-user-modal-module--button-icon-base--1cb84";
export var clickLink = "remove-staff-user-modal-module--click-link--b990f";
export var dropdownBase = "remove-staff-user-modal-module--dropdown-base--4e603";
export var dropdownSelectBase = "remove-staff-user-modal-module--dropdown-select-base--020f9 remove-staff-user-modal-module--text-input--f017c";
export var flexCol = "remove-staff-user-modal-module--flex-col--69f24";
export var formErrorMessage = "remove-staff-user-modal-module--form-error-message--09b4e";
export var h3 = "remove-staff-user-modal-module--h3--a31f8";
export var h4 = "remove-staff-user-modal-module--h4--841dd";
export var hoverLink = "remove-staff-user-modal-module--hover-link--bc280";
export var hoverRow = "remove-staff-user-modal-module--hover-row--95941";
export var membershipContainer = "remove-staff-user-modal-module--membership-container--74957 remove-staff-user-modal-module--flex-col--69f24 remove-staff-user-modal-module--primary-border--69614";
export var membershipHeader = "remove-staff-user-modal-module--membership-header--142bc";
export var membershipHeading = "remove-staff-user-modal-module--membership-heading--925b8";
export var membershipLabel = "remove-staff-user-modal-module--membership-label--78a91";
export var modalContent = "remove-staff-user-modal-module--modalContent--5646e";
export var moreFiltersBorderClass = "remove-staff-user-modal-module--more-filters-border-class--62445";
export var pageBg = "remove-staff-user-modal-module--page-bg--13396";
export var pointer = "remove-staff-user-modal-module--pointer--86eae";
export var primaryBorder = "remove-staff-user-modal-module--primary-border--69614";
export var shadowBoxLight = "remove-staff-user-modal-module--shadow-box-light--eabd9";
export var siteFont = "remove-staff-user-modal-module--site-font--f35b6";
export var slideDownAndFade = "remove-staff-user-modal-module--slideDownAndFade--e0798";
export var slideLeftAndFade = "remove-staff-user-modal-module--slideLeftAndFade--b591b";
export var slideRightAndFade = "remove-staff-user-modal-module--slideRightAndFade--d96d8";
export var slideUpAndFade = "remove-staff-user-modal-module--slideUpAndFade--e51b3";
export var statusDecoration = "remove-staff-user-modal-module--status-decoration--b71c0";
export var textInput = "remove-staff-user-modal-module--text-input--f017c";
export var textInverted = "remove-staff-user-modal-module--text-inverted--02e89";
export var textMediumDark = "remove-staff-user-modal-module--text-medium-dark--b1b9d";
export var tooltipFont = "remove-staff-user-modal-module--tooltipFont--628d7";
export var unstyledButton = "remove-staff-user-modal-module--unstyled-button--25525";