import React, { useCallback, useMemo, useState } from 'react';

import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable from 'src/components/admin-table/admin-table';
import { useColumnDefinitions } from 'src/components/admin-table/hooks';
import Button from 'src/components/button/button';
import ExpandedMenu, { ExpandedMenuProps } from 'src/components/expanded-menu/expanded-menu';
import { withNotifications } from 'src/components/notification-container/notification-container';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { useRemoveStaffUserModal } from 'src/components/remove-staff-user-modal/remove-staff-user-modal';
import { ControlProps, ROWS_PER_PAGE_OPTIONS } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { StaffScope, useHasScope } from 'src/utils/auth';
import { getVenueAdmins } from 'src/utils/classic-api';
import ROUTES from 'src/utils/routes';
import { retrieveRowsPerPage } from 'src/utils/storage/local-storage';

import { defaultColumnsConfig } from './config';
import { StaffData, StaffUsersProps } from './types';
import { copyInviteLink } from './utils';

const StaffUsers = ({ transKeyTitle = 'staff users', columnsConfig = defaultColumnsConfig }: StaffUsersProps) => {
  const { t } = useTranslation();
  const [staff, setStaff] = useState<StaffData[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const orgId = useOrgId();
  const [loadingData, setLoading] = useState(false);
  const { RemoveStaffUserModal, setIsOpen: setIsModalOpen } = useRemoveStaffUserModal();

  const getSortOrder = useCallback((key) => {
    if (key === 'ASCENDING') return 0;
    if (key === 'DESCENDING') return 1;
  }, []);

  const fetchStaffUsers = useCallback(
    async (options: {
      orgId: string;
      limit?: number;
      offset?: number;
      sortingOrder?: string;
      sortingDirection?: number;
    }) => {
      setLoading(true);

      try {
        const res = await getVenueAdmins(options);
        const staffMembers = res?.Items?.map((i) => ({ ...i, id: i.ID }));
        setStaff(staffMembers);
        setTotalItems(res?.TotalItems);
      } catch (err) {
        toast.error(t('staffUserModule.queryErrors.fetchStaffUsers'));
      }

      setLoading(false);
    },
    [t],
  );

  const onControlChange = useCallback<ControlProps['onControlChange']>(
    async ({ pagination, sorting }) => {
      await fetchStaffUsers({
        orgId,
        limit: pagination?.limit,
        offset: pagination?.offset,
        sortingOrder: sorting?.orderBy,
        sortingDirection: getSortOrder(sorting?.order),
      });
    },
    [orgId, getSortOrder, fetchStaffUsers],
  );

  const controls = useMemo<ControlProps>(
    () => ({
      onControlChange,
      totalItems,
    }),
    [totalItems, onControlChange],
  );

  const [selectedStaff, setSelectedStaff] = useState<StaffData>();
  const handleRemoveClick = useCallback(
    (staff: StaffData) => {
      setSelectedStaff(staff);
      setIsModalOpen(true);
    },
    [setIsModalOpen],
  );

  const onRemoveSuccess = useCallback(async () => {
    await fetchStaffUsers({
      orgId,
      limit: retrieveRowsPerPage() ?? ROWS_PER_PAGE_OPTIONS[0],
      offset: 0,
    });
  }, [orgId, fetchStaffUsers]);

  const onRemoveFail = useCallback(() => {
    toast.error(t('delete staff error'));
  }, [t]);

  const userCanEdit = useHasScope([StaffScope.STAFF, StaffScope.SUPERADMIN]);

  const getItems = useCallback(
    (staff) => {
      const items: ExpandedMenuProps['items'] = [];
      if (userCanEdit) {
        items.push({
          key: 'edit',
          label: t('edit'),
          onClick: () => navigate(`/staff/edit-staff/${staff.id}`, { state: { staff: staff } }),
        });
      }
      if (staff.InviteUrl) {
        items.push({
          key: 'copy-invite',
          label: t('copy invite link'),
          onClick: () => copyInviteLink(staff.InviteUrl),
        });
      }
      if (userCanEdit) {
        items.push({
          key: 'remove',
          label: t('remove'),
          onClick: () => handleRemoveClick(staff),
          warning: true,
        });
      }
      if (items.length === 0) {
        items.push({ key: 'none', label: t('no actions available') });
      }
      return items;
    },
    [t, userCanEdit, handleRemoveClick],
  );

  const configurableColumns = useColumnDefinitions(columnsConfig);
  const columns = useMemo(() => {
    const actionCol = {
      key: 'Actions',
      getValue: (d: StaffData) => (
        <ExpandedMenu
          items={getItems(d)}
          buttonText={t('')}
          buttonIcon
          iconName="md-more-options"
          anchorElement="icon"
        />
      ),
      title: t('actions'),
      sort: false,
    };

    return [...configurableColumns, actionCol];
  }, [t, configurableColumns, getItems]);

  return (
    <PageMaxWidth>
      <PageHeader title={t(transKeyTitle)} />
      <Panel
        headerEndContent={
          userCanEdit && (
            <Button spacing={{ margins: { sm: 'bottom' } }} onClick={() => navigate(ROUTES.ADD_STAFF)}>
              {t('add staff')}
            </Button>
          )
        }
      >
        <AdminTable controls={controls} loading={loadingData} columns={columns} data={staff} />
      </Panel>
      {selectedStaff && (
        <RemoveStaffUserModal
          staffUser={selectedStaff as any}
          onRemoveSuccess={onRemoveSuccess}
          onRemoveFail={onRemoveFail}
        />
      )}
    </PageMaxWidth>
  );
};

export default withNotifications(StaffUsers);
